var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "min-height": "100vh" }, attrs: { id: "round-bg" } },
    [
      _c("div", { staticClass: "interests-table-content" }, [
        _c("div", { staticClass: "interests-table-header" }, [
          _c("div", [
            _c("h1", [_vm._v(_vm._s(_vm.$t("allocations")))]),
            _c("p", [_vm._v(_vm._s(_vm.$t("interest_table_sub_title")))]),
          ]),
          _c(
            "div",
            { staticClass: "interests-table-header-btn-new-allocation" },
            [
              _c("NewDxaButton", {
                attrs: {
                  outline: false,
                  title: _vm.$t("create_new_allocation"),
                },
                on: {
                  btnFunction: function () {
                    return _vm.console.log("this is a function")
                  },
                },
              }),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "interest-table-container-table" },
          [_c("AllocationsTableComponent")],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }